import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { createLogEntry } from '../Api'

function LogEntryForm({ location, onClose }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        try {
            setLoading(true)
            data.latitude = location.latitude;
            data.longitude = location.longitude;
            await createLogEntry(data);

            onClose();
        } catch (error) {
            console.error(error);
            setError(error.message)
            setLoading(false)
        }

    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="entry-form">
                {error ? <h3 className="error">{error}</h3> : null}
                <label htmlFor="apiKey">API KEY</label>
                <input type="password" name="apiKey" required ref={register} />
                <label htmlFor="title">Title</label>
                <input name="title" required ref={register} />
                <label htmlFor="comments">Comments</label>
                <textarea name="comments" rows={3} ref={register}></textarea>
                <label htmlFor="comments">Description</label>
                <textarea name="comments" rows={3} ref={register}></textarea>
                <label htmlFor="image">Image</label>
                <input name="image" ref={register} />
                <label htmlFor="visitDate">Visit Date</label>
                <input name="visitDate" type="date" required ref={register} />
                <button disabled={loading} >{loading ? 'loading' : 'Create Entry'}</button>
            </form>

        </div>
    )
}

export default LogEntryForm
